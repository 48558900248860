<template>
  <section v-if="models && models?.length > 0" id="instock" class="instock">
    <h2 class="instock__title">Автомобили в наличии</h2>
    <div class="models__menu instock__menu">
      <button
        class="models__menu__item instock__menu-item"
        v-for="model in models"
        :key="model"
        @click="handleSelectedModel(model)"
      >
        {{ model }}
      </button>
    </div>
    <TabComponent
      :selectedModel="selected_model"
      :model="selected_model.length"
    />
    <TabsSlider
      class="tabs-swiper"
      :selectedModel="selected_model"
      :tabs="selected_model"
    />
  </section>
</template>

<script>
import axios from "axios";
import siteData from "@/config.json";
import TabComponent from "./TabComponent.vue";
import TabsSlider from "./TabsSlider.vue";
export default {
  name: "TabsComponent",
  data() {
    return {
      API_DATA: null,
      models: null,
      selected_model: [],
      URL: process.env.VUE_APP_API_URL,
    };
  },
  components: { TabComponent, TabsSlider },
  async mounted() {
    window.addEventListener("resize", this.getDimensions);
    await this.getData();
    if (!this.selected_model.length)
      this.selected_model = this.API_DATA[this.models[0]];
  },
  methods: {
    toggleRequestForm(event) {
      this.emitter.emit("open-request-form", {
        open: false,
        title: event.target.innerText,
      });
    },
    //getting data
    async getData() {
      const getDataResponse = await axios
        .get(`${this.URL}cars`)
        .then((response) => {
          return response;
        })
        .catch((error) => console.log(error));
      this.API_DATA = getDataResponse.data;
      this.models = Object.keys(this.API_DATA);
    },
    handleSelectedModel(model) {
      this.selected_model = this.API_DATA[model];
    },
  },
  computed: {
    showSelectedModel() {
      let filterdArray = this.selected_model;
      return filterdArray.map((car) => car);
    },
    filterByAddress() {
      let filterdArray = this.selected_model.map((car) => car);
      //getting first address
      let address_1 = filterdArray.filter((car) => {
        return car.location.includes("144");
      });
      //modifying arrays
      let mixedAddress = [];
      for (let i = 0; i <= filterdArray.length; i++) {
        mixedAddress.push(address_1[0]);
        mixedAddress.push(address_1[1]);
        address_1.splice(0, 2);
      }
      //handeling null items in array
      let newArray = mixedAddress.filter((v, i, a) => a.indexOf(v) === i);
      //return new unique array
      return newArray.filter((car) => car != null);
    },
    // filteredModels() {
    //   const execluded = ["Tiggo 8 Pro", "Tiggo 8"];
    //   const filtered = this.models?.filter((item) => !execluded.includes(item));
    //   return filtered;
    // },
  },
};
</script>

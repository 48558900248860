<template>
  <Header />
  <MainSlider1 />
  <Models />
  <MainSlider2 />
  <InStock />
  <Service />
  <AboutUs />
  <YandexMapComponent />
  <ContactFormComponent />
  <FooterComponent />
  <RequestFormComponent />
  <PrivacyComponent />
</template>

<script>
import Header from "./components/header/HeaderComponent.vue";
import RequestFormComponent from "./components/request-form/RequestFormComponent.vue";
import MainSlider1 from "./components/main-slider-1/MainSliderComponent.vue";
import MainSlider2 from "./components/main-slider-2/MainSliderComponent.vue";
import Models from "./components/models-range/ModelsComponent.vue";
import InStock from "./components/in-stock/TabsComponent.vue";
import AboutUs from "./components/about-us/AboutUsComponent.vue";
import Service from "./components/service-section/ServiceComponent.vue";
import YandexMapComponent from "./components/yandex-map/YandexMapComponent.vue";
import ContactFormComponent from "./components/contact-form/ContactFormComponent.vue";
import FooterComponent from "./components/footer/FooterComponent.vue";
import PrivacyComponent from "./components/privacy/PrivacyComponent.vue";

export default {
  name: "App",
  components: {
    Header,
    MainSlider1,
    MainSlider2,
    Models,
    InStock,
    AboutUs,
    Service,
    YandexMapComponent,
    ContactFormComponent,
    FooterComponent,
    RequestFormComponent,
    PrivacyComponent,
  },
};
</script>

<style lang="scss">
@import "./scss/style";
</style>
